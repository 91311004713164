import { Controller } from "@hotwired/stimulus"

export default class extends Controller<HTMLFormElement> {
  static targets: string[] = ['field']
  declare readonly fieldTargets: HTMLInputElement[]
  connect() {
  }

  validate(event: Event) {
    this.fieldTargets.forEach(field => {
      field.parentElement?.classList.remove("border-red-500", "border-2")
    })
    this.fieldTargets.forEach(field => {
      if (field.dataset.invalid !== "true") return
      event.preventDefault()
      field.parentElement?.classList.add("border-2", "border-red-500")
    })
    const firstInvalidField = this.fieldTargets.find(field => {
      return field.dataset.invalid === "true"
    })
    firstInvalidField?.parentElement?.scrollIntoView()
    if (firstInvalidField && firstInvalidField.dataset.invalidReason) {
      alert(firstInvalidField.dataset.invalidReason)
    }
  }
}
