import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
import observeRect from "@reach/observe-rect";
import { classMap } from "lit/directives/class-map.js";

@customElement('searchable-single-select')
export class SearchableSingleSelect extends LitElement {
  @property()
  focused: boolean = false;

  selectInputElement: HTMLSelectElement

  required: boolean = false;

  @property()
  queryString: string = ""

  @property()
  width: Number

  @property()
  top: Number

  @property()
  left: Number

  @property()
  hoveredOptionId: string

  static styles = css`
    :root {
      font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
    }

    div.container {
      display: flex;
      width: 100%;
      padding: 0.5rem;
      box-sizing: border-box;
      background-color: white;
      border-width: 1px;
      border-color: rgb(203,213,225);
      border-style: solid;
      height: 42px;
      position: relative;
    }

    div.container > .query-input {
      font-size: 16px;
      border-width: 0;
      width: 100%;
      outline-width: 0;
      padding: 0;
      cursor: default;
    }

    div.dropdown {
      background-color: white;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
      border-bottom-right-radius: 0.375rem; /* 6px */
      border-bottom-left-radius: 0.375rem; /* 6px */
      border-width: 1px;
      border-style: solid;
      border-color: rgb(203,213,225);
      border-top-width: 0;
      overflow: auto;
      max-height: 15rem;
    }

    div.option {
      padding-left: 4px;
      padding-right: 4px;
      cursor: pointer;
      padding-top: 4px;
      padding-bottom: 4px;
      min-height: 28px;
      display: flex;
      align-items: center;
    }

    div.option.hovering {
      background-color: #3b82f6 !important;
      color: white;
    }

    div.option.selected {
      background-color: #cbd5e1;
    }
  `

  constructor() {
    super()
    this.selectInputElement = this.querySelector('select') as HTMLSelectElement
    this.tabIndex = 0
    this.addEventListener('keydown', this.onKeyDown.bind(this))

    if (this.selectInputElement.required) {
      this.required = true
      this.dataset.formvalidatorTarget = "field"
      this.selectInputElement.required = false // remove the html required attribute to avoid not focusable error
      this.checkValidity()
    }
  }

  firstUpdated() {
    observeRect(this.shadowRoot.getElementById('mainContainer'), rect => {
      this.left = rect.left
      this.top = rect.bottom
      this.width = rect.width
    }).observe()
  }

  updated() {
    const queryInput = this.shadowRoot.getElementById('queryInput') as HTMLInputElement
    if (queryInput) { queryInput.focus() }
    this.checkValidity()
  }

  checkValidity() {
    if (!this.required) { return }
    if (this.selectInputElement.value) {
      this.dataset.invalid = "false"
    } else {
      this.dataset.invalid = "true"
    }
  }

  onContainerClick(e: Event) {
    this.queryString = ""
    this.focused = true
  }

  onQueryBlur(e: FocusEvent) {
    this.focused = false
  }

  onQueryInput(e: Event) {
    const target = e.target as HTMLInputElement
    this.queryString = target.value
  }

  onOptionHovered(e: Event) {
    const target = e.target as HTMLDivElement
    this.hoveredOptionId = target.id
  }

  onOptionClicked(e: Event) {
    console.log(e)
    const target = e.target as HTMLDivElement
    const optionId = parseInt(target.id.split('_')[1])
    this.selectOption(optionId)
  }

  onKeyDown(e: KeyboardEvent) {
    const mod = this.selectInputElement.options.length
    const hoveredOptionId = parseInt(this.hoveredOptionId.split('_')[1])

    if (this.focused) {
      if (e.key == 'Escape') {
        this.focused = false
      } else if (e.key == 'Enter') {
        const optionId = parseInt(this.hoveredOptionId.split('_')[1])
        this.selectOption(optionId)
        this.focused = false
      } else if (e.key == 'ArrowUp') {
        this.hoveredOptionId = `option_${(((hoveredOptionId-1) % mod) + mod) % mod}`
        console.log(this.hoveredOptionId)
      } else if (e.key == 'ArrowDown') {
        this.hoveredOptionId = `option_${(((hoveredOptionId+1) % mod) + mod) % mod}`
      }
    } else {
      if (e.key == 'Enter') {
        this.focused = true
      }
    }
  }

  selectOption(optionId: number) {
    this.selectInputElement.selectedIndex = optionId
    this.requestUpdate()
  }

  // renderOptions collides with another name so we use renderOptionss
  renderOptionss() {
    let candidates = []
    let anyOptionHovering = false
    for (const option of this.selectInputElement.options) {
      if (! option.innerText.toLowerCase().includes(this.queryString.toLowerCase())) { continue }
      candidates.push(option)
      if (`option_${option.index}` == this.hoveredOptionId) { anyOptionHovering = true }      
    }

    let out = []
    let firstRun = true
    for (const option of candidates) {
      if (firstRun && !anyOptionHovering) {
        this.hoveredOptionId = `option_${option.index}`
        firstRun = false
      }
      const classes = {
        hovering: (`option_${option.index}` === this.hoveredOptionId), 
        selected: (this.selectInputElement.selectedIndex === option.index)
      }
      out.push(html`
        <div @mousedown=${this.onOptionClicked} id="option_${option.index}" class="option ${classMap(classes)}" @mouseover=${this.onOptionHovered}>${option.innerText}</div>
      `)
    }

    return out
  }

  renderPopup() {
    return html`
      <div tabindex="0" style="position: fixed; width: ${this.width}px; left: ${this.left}px; top: ${this.top}px;" ?hidden=${!this.focused}>
        <div class="dropdown">
          ${this.renderOptionss()}
        </div>
      </div>
    `
  }

  renderQueryInput() {
    if (this.focused) {
      return html`
        <input @blur=${this.onQueryBlur} @input=${this.onQueryInput} autocomplete="off" id="queryInput" class="query-input" type="text" />
      `
    } else {
      return html`
        <span class="query-input">${this.selectInputElement.selectedOptions[0].innerText}</span>
      `
    }
  }

  render() {
    return html`
      <div id="mainContainer" @click=${this.onContainerClick} class="container">
        ${this.renderQueryInput()}
        <div style="width: 10px;">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
        </div>
      </div>
      ${this.renderPopup()}
    `
  }
}